/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// You can delete this file if you're not using it
import "./src/tailwind/global.css"
import React from "react"
import PageWrapper from "./src/components/PageWrapper"

export const wrapPageElement = PageWrapper
