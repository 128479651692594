import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()
export default function PageWrapper({ element, props }) {
  return (
    <QueryClientProvider client={queryClient}>
      <div {...props}>{element}</div>
    </QueryClientProvider>
  )
}
